/* @flow */

import {
    requestGetCashRegister,
    requestUpdateCashRegister,
  } from './client';

import deepMerge from 'lodash/merge';
import c from '../constants';

import {
  getCacheData,
  saveCacheData,
} from './products';


export function getCashRegister(): Function {
    return async (dispatch: Function) => {
      try {
        const cashRegisterData = await requestGetCashRegister();
        dispatch({
          type: c.UPDATE_CASH_REGISTER,
          payload: cashRegisterData,
        });

        return cashRegisterData;
      } catch (error) {
        console.error('Error obteniendo caja registradora:', error);
        throw error;
      }
    };
  }

  export function  openCashRegister(amount, branchId): Function {
    return async (dispatch , getState) => {
      try {
        const state = getState();
        const oldCashRegister = deepMerge({}, state.client.cashRegister);
        const newData = {
          ...oldCashRegister,
          [branchId]:{
            amount,
            isOpen:true,
            sales:[],
          }
          }
        const cashRegisterData = await requestUpdateCashRegister(newData);
        dispatch({
          type: c.UPDATE_CASH_REGISTER,
          payload: cashRegisterData,
        });

        return cashRegisterData;
      } catch (error) {
        console.error('Error abriendo caja registradora:', error);
        throw error;
      }
    };
  }

  export function  closeCashRegister(branchId): Function {
    return async (dispatch , getState) => {
        try {
        const state = getState();
        const oldCashRegister = deepMerge({}, state.client.cashRegister);
          const newData = {
            ...oldCashRegister,
            [branchId]:{
              amount:oldCashRegister[branchId].amount,
              isOpen:false,
              sales: oldCashRegister[branchId].sales,
            }
            }
          const cashRegisterData = await requestUpdateCashRegister(newData);
          dispatch({
            type: c.UPDATE_CASH_REGISTER,
            payload: cashRegisterData,
          });
  
          return cashRegisterData;
        } catch (error) {
          console.error('Error cerrando caja registradora:', error);
          throw error;
        }
      };
  }
