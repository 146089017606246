/* @flow */

import {
  requestSetConfigs,
  requestGetConfigs,
} from './actions/client';

import {
  getCacheData,
  saveCacheData,
} from './actions/products';

const configs = {
  name: 'Nea', // 'Test' \ 'Nea'
  configs: {},
};

export function setConfig(values:Object) {
  if (typeof values.configs === 'string') {
    values.configs = JSON.parse(values.configs);
  }
  Object.assign(configs, values);
}

export function loadConfig():Promise<any> {
  const cacheData = getCacheData();
  if (
    cacheData.config &&
    cacheData.configDate > new Date().getTime() - 1000 * 60 * 60 // 10 minutes
  ) {
    setConfig(cacheData.config);
    return Promise.resolve(cacheData.config);
  }
  return requestGetConfigs().then((res) => {
    saveCacheData({
      config: res,
      configDate: new Date().getTime(),
    });
    setConfig(res);
  });
}

export function getConfig(key:any):Object {
  if (key === 'name') return configs.name;
  if (key) return configs.configs[key];
  return configs;
}

export function updateConfigs(newValues:Object):Function {
  return (dispatch, getState) => {
    const configsDataString = JSON.stringify({
      ...(configs.configs || {}),
      ...newValues,
    });
    return requestSetConfigs(configsDataString)
      .then((res) => {
        setConfig(configsDataString);
      })
      .catch((err) => {
        window.alert('Error editing configs');
      });
  };
}
