/* @flow */

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css'; // Archivo de estilos para el footer

function Footer(props: Object): Object {
  return (
    <div className="mb-footer">
      <div className="footer-links">
        <div className="sales-links">
          <h4>Mas</h4>
          <ul>
            <li><NavLink strict="true" to="/about">
              {window.lc('Sobre Nosotros')}
            </NavLink></li>
            <li><NavLink strict="true" to="/branches">
              {window.lc('Sucursales')}
            </NavLink></li>
            <li><NavLink strict="true" to="/politics">
              {window.lc('Politicas')}
            </NavLink></li>
          </ul>
        </div>
        <div className="social-links">
          <h4>Redes Sociales</h4>
          <ul>
            <li><a href="https://facebook.com/tuFacebook" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://twitter.com/tuTwitter" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://instagram.com/tuInstagram" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
