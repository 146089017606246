/* @flow */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import Input from 'rsuite/Input';
import DatePicker from 'rsuite/DatePicker';
import Notification from 'rsuite/Notification';
import SelectPicker from 'rsuite/SelectPicker';
import { useNavigate } from 'react-router-dom';
import { getCurrencyTypeDescription } from '../utils/currencyTypes';
import { addProductToOffers } from '../actions/offers';
import useToaster from 'rsuite/useToaster';
import './OfferProduct.css';

function OfferProduct({ show, onHide, productId, product }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toaster = useToaster();


  const defaultOfferValue = '';
  const defaultProductQuantity = 2;
  const defaultStartDate = null;
  const defaultEndDate = null;
  const defaultOfferType = null;
  const defaultDescription = '';
  const defaultApplicability = 'all';


  const [offerValue, setOfferValue] = useState(defaultOfferValue);
  const [productQuantity, setProductQuantity] = useState(defaultProductQuantity);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [offerType, setOfferType] = useState(defaultOfferType);
  const [description, setDescription] = useState(defaultDescription);
  const [applicability, setApplicability] = useState(defaultApplicability);

  useEffect(() => {
    if (show) {
      setOfferValue(defaultOfferValue);
      setProductQuantity(defaultProductQuantity);
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      setOfferType(defaultOfferType);
      setDescription(defaultDescription);
      setApplicability(defaultApplicability);
    }
  }, [show]);


  const offerTypes = [
    { label: 'Descuento por Monto', value: 'amount' },
    { label: 'Porcentaje de Descuento', value: 'percentage' },
    { label: 'Descuento por Cantidad de Producto', value: 'productQuantity' }
  ];


  const applicabilityOptions = [
    { label: 'Todos los Clientes', value: 'all' },
    { label: 'Solo Clientes VIP', value: 'vip' }
  ];


  const handleSubmit = () => {
    if (!offerType) {
      toaster.push(
        <Notification type="error" header="Error" closable>
          <p>Por favor, seleccione un tipo de oferta.</p>
        </Notification>,
        { placement: 'topEnd', duration: 5000 }
      );
      return;
    }

    if (!startDate || !endDate || startDate >= endDate) {
      toaster.push(
        <Notification type="error" header="Error" closable>
          <p>Por favor, seleccione un rango de fechas válido para la oferta.</p>
        </Notification>,
        { placement: 'topEnd', duration: 5000 }
      );
      return;
    }

    if (offerType !== 'productQuantity' && (isNaN(offerValue) || offerValue <= 0)) {
      toaster.push(
        <Notification type="error" header="Error" closable>
          <p>Por favor, introduzca un valor válido para la oferta.</p>
        </Notification>,
        { placement: 'topEnd', duration: 5000 }
      );
      return;
    }

    if (offerType === 'productQuantity') {
      if (isNaN(offerValue) || offerValue <= 0 || offerValue > 100) {
        toaster.push(
          <Notification type="error" header="Error" closable>
            <p>Por favor, introduzca un porcentaje de descuento válido (entre 1 y 100%).</p>
          </Notification>,
          { placement: 'topEnd', duration: 5000 }
        );
        return;
      }
      if (isNaN(productQuantity) || productQuantity < 2) {
        toaster.push(
          <Notification type="error" header="Error" closable>
            <p>Por favor, introduzca una cantidad de productos válida (mínimo 2).</p>
          </Notification>,
          { placement: 'topEnd', duration: 5000 }
        );
        return;
      }
    }

    const offerInfo = {
      offerType,
      offerValue,
      productQuantity: offerType === 'productQuantity' ? productQuantity : null, 
      description,
      startDate,
      endDate,
      applicability
    };

    dispatch(addProductToOffers(productId, offerInfo))
      .then(() => {
        toaster.push(
          <Notification type="success" header="Éxito" closable>
            <p>La oferta se ha establecido correctamente.</p>
          </Notification>,
          { placement: 'topEnd', duration: 5000 }
        );
        setTimeout(() => {
          navigate('/');
          onHide();
        }, 3000);
      })
      .catch((error) => {
        console.error('Error adding product to offers:', error);
        toaster.push(
          <Notification type="error" header="Error" closable>
            <p>Ocurrió un error al establecer la oferta. Por favor, inténtelo de nuevo más tarde.</p>
          </Notification>,
          { placement: 'topEnd', duration: 5000 }
        );
      });
  };


  const renderOfferValueInput = () => {
    if (!offerType) return null;

    let placeholder = '';
    let label = '';

    switch (offerType) {
      case 'amount':
        placeholder = 'Introduce el monto que se descontara al producto';
        label = 'Monto de Descuento:';
        return (
          <div className="offer-product-section">
            <label>{label}</label>
            <Input
              type="number"
              value={offerValue}
              onChange={value => setOfferValue(value)}
              placeholder={placeholder}
            />
          </div>
        );
      case 'percentage':
        placeholder = 'Introduce el porcentaje de descuento';
        label = 'Porcentaje de Descuento:';
        return (
          <div className="offer-product-section">
            <label>{label}</label>
            <Input
              type="number"
              value={offerValue}
              onChange={value => setOfferValue(value)}
              placeholder={placeholder}
            />
          </div>
        );
      case 'productQuantity':
        return (
          <>
            <div className="offer-product-section">
              <label>Porcentaje de Descuento:</label>
              <Input
                type="number"
                value={offerValue}
                onChange={value => setOfferValue(value)}
                placeholder="Introduce el porcentaje de descuento"
              />
            </div>
            <div className="offer-product-section">
              <label>El descuento se aplica en el producto numero:</label>
              <Input
                type="number"
                value={productQuantity}
                min={2}
                max={5}
                onChange={value => setProductQuantity(value)}
                placeholder="Introduce la cantidad de productos"
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={show} onClose={onHide} size="sm">
      <Modal.Header>
        <Modal.Title>Establecer Oferta para {product?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="offer-product-section">
          <label>Precio Antes de Oferta: {product?.cost} {getCurrencyTypeDescription(product?.currency)}</label>
        </div>
        <div className="offer-product-section">
          <label>Tipo de Oferta:</label>
          <SelectPicker
            data={offerTypes}
            value={offerType}
            onChange={setOfferType}
            placeholder="Seleccione el tipo de oferta"
            style={{ width: '100%' }}
          />
        </div>
        {renderOfferValueInput()}
        <div className="offer-product-section">
          <label>Descripción (opcional):</label>
          <Input
            type="text"
            value={description}
            onChange={value => setDescription(value)}
            placeholder="Añadir una descripción (opcional)"
          />
        </div>
        <div className="offer-product-section">
          <label>Aplicabilidad de la Oferta:</label>
          <SelectPicker
            data={applicabilityOptions}
            value={applicability}
            onChange={setApplicability}
            placeholder="Seleccione la aplicabilidad"
            style={{ width: '100%' }}
          />
        </div>
        <div className="offer-product-section">
          <label>Fecha de Inicio de la Oferta:</label>
          <DatePicker
            format="yyyy-MM-dd"
            value={startDate}
            onChange={value => setStartDate(value)}
            placeholder="Seleccione la fecha de inicio"
          />
        </div>
        <div className="offer-product-section">
          <label>Fecha de Fin de la Oferta:</label>
          <DatePicker
            format="yyyy-MM-dd"
            value={endDate}
            onChange={value => setEndDate(value)}
            placeholder="Seleccione la fecha de fin"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} appearance="subtle">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} appearance="primary">
          Guardar Oferta
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OfferProduct;
