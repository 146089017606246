/* @flow */

const c = {
  SET_USER: 1,
  UPDATE_USER: 2,
  SIGN_OUT: 3,
  UPDATE_PRODUCT_TYPES: 4,
  UPDATE_PRODUCTS: 5,
  ADD_PRODUCT_TO_CART: 6,
  REMOVE_PRODUCT_TO_CART: 7,
  UPDATE_PRODUCT: 8,
  UPDATE_COMMENTS: 9,
  ADD_PRODUCT_TO_FAVORITES: 10,
  REMOVE_PRODUCT_TO_FAVORITES: 11,
  UPDATE_FAVORITES: 12, 
  UPDATE_CART:13,
  UPDATE_BRANCH:14,
  UPDATE_BRANCHES:15,
  ADD_BRANCH: 16, 
  REMOVE_BRANCH:17,
  DELETE_COMMENT:18,
  ADD_ORDER:19,
  CLEAR_CART:20,
  UPDATE_ORDERS:21,
  UPDATE_ORDER:22,
  UPDATE_USERS:23,
  UPDATE_HOME:24,
  UPDATE_CLIENTS:25,
  UPDATE_SALES:26,
  UPDATE_CASH_REGISTER:27,
};

if (process.env.NODE_ENV === 'development') {
  Object.keys(c).forEach((k:string) => {
    c[k] = k;
  });
} else {
  Object.keys(c).forEach((k:string) => {
    c[k] = `${c[k]}`;
  });
}

export default c;
