/* @flow */

import c from '../constants';

const defaultState = {
  list: [],
};

export default function tags(state:Object = defaultState, action:Object = {}):Object {
  switch (action.type) {
    default:
      break;
  }
  return state;
}
