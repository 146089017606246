/* @flow */

import CashRegister from '../components/CashRegister';
import c from '../constants';

const defaultState = {
  users: [],
  clients:[],
  sales: [],
  home:{},
  cashRegister:{},
};

export default function products(state:Object = defaultState, action:Object = {}):Object {
  switch (action.type) {
    case c.UPDATE_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case c.UPDATE_HOME: {
      return {
        ...state,
        home: action.payload,
      };
    }
    case c.UPDATE_CLIENTS: {
      return {
        ...state,
        clients: action.payload,
      };
    }
    case c.UPDATE_SALES: {
      return {
        ...state,
        sales: action.payload,
      };
    }
    case c.UPDATE_CASH_REGISTER: {
      return {
        ...state,
        cashRegister: action.payload,
      };
    }
    
    
    default:
      return state;
  }
}