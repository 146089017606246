/* @flow */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import Carousel from 'rsuite/Carousel';
import { useNavigate } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';

import 'react-alice-carousel/lib/alice-carousel.css';

import { getHome } from '../actions/home';
import { getOffers } from '../actions/offers';
import { getCurrencyTypeDescription } from '../utils/currencyTypes';
import {
  toggleProductFromFavorite,
} from '../actions/products';
import {
  addProductToCart,
} from '../actions/account';

import Footer from '../components/Footer';

import './Home.css';

function Home():Object {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const home = useSelector(state => state.client.home);
  const products = useSelector(state => state.products);
  const cart = useSelector(state => state.account.cart);
  const currentUser = useSelector(state => state.account.user) || {};
  const coverCarousel = home.carousel || [];
  const homeProducts = home.products || {};
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    dispatch(getOffers());
    if (Object.keys(home).length === 0) {
      dispatch(getHome());
    }
  }, []);

  if (Object.keys(home).length === 0) {
    return <div>Cargando...</div>;
  }

  const openProductModal = product => {
    setSelectedProduct(product);
  };

  const closeProductModal = () => {
    setSelectedProduct(null);
  };

  const onClickProduct = (productId) => {
    navigate(`/product/${productId}`);
  };

  const addProductClick = (product, variant, e) => {
    e.stopPropagation();
    if (variant) {
      dispatch(addProductToCart(product.id, variant, 1));
    } else {
      dispatch(addProductToCart(product.id, null, 1));
    }
  };

  const toggleFavoriteClick = (product, e) => {
    e.stopPropagation();
    const isFavorite = products.favorites.includes(product.id);
    dispatch(toggleProductFromFavorite(product, isFavorite));
  };

  const isProductInCart = (productId) => {
    return !!cart[productId];
  };

  const calculateBestOffer = (product) => {
    const offers = product.data.offers;
    const cost = parseFloat(product.data.cost);
    let bestOffer = null;
    let lowestPrice = cost;

    if (offers) {
      Object.keys(offers).forEach((key) => {
        const offer = offers[key];
        const offerValue = parseFloat(offer.offerValue);
        let finalPrice = cost;

        const now = new Date();
        const startDate = new Date(offer.startDate.seconds * 1000);
        const endDate = new Date(offer.endDate.seconds * 1000);

        if (now >= startDate && now <= endDate) {
          const isVIP = currentUser.vip;

          if (offer.applicability === 'vip' && !isVIP) {
            return;
          }

          if (offer.offerType === 'amount') {
            finalPrice = cost - offerValue;
          } else if (offer.offerType === 'percentage') {
            finalPrice = cost - (cost * (offerValue / 100));
          } else if (offer.offerType === 'productQuantity') {
            finalPrice = cost;
          }

          if (finalPrice <= lowestPrice) {
            lowestPrice = finalPrice;
            bestOffer = offer;
          }
        }
      });
    }

    return { bestOffer, lowestPrice };
  };

  const renderOfferPrices = (product, bestOffer, lowestPrice) => {
    const hasOffer = product.data.offers && Object.keys(product.data.offers).length > 0;

    if (hasOffer && bestOffer && product.data.variables && product.data.variables.length > 0) {
      // Render a carousel for each variant's price
      return (
        <Carousel className="previews-slider" shape="bar" autoplay interval={2000} >
          {product.data.variables.map((variant, index) => {
            const variantCost = parseFloat(variant.cost);
            const variantBestOffer = calculateBestOffer({ ...product, data: { ...product.data, cost: variantCost } }).bestOffer;
            const variantLowestPrice = calculateBestOffer({ ...product, data: { ...product.data, cost: variantCost } }).lowestPrice;

            return (
              <div key={index} style={{background:'white', textAlign:'center'}}>
                <span>{variant.name} : </span>
                <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '10px' }}>
                  {`${variant.cost} ${getCurrencyTypeDescription(product.data.currency)}`}
                </span>
                {variantBestOffer.offerType !== 'productQuantity' ? (
                  <span>
                    {`${variantLowestPrice.toFixed(2)} ${getCurrencyTypeDescription(product.data.currency)}`}
                  </span>
                ) : (
                  <span>
                    {`${variantBestOffer.offerValue} % menos en el ${variantBestOffer.productQuantity} productos`}
                  </span>
                )}
              </div>
            );
          })}
        </Carousel>
      );
    } else if (hasOffer && bestOffer) {
      // Render single offer price for the product
      return (
        <>
          <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '10px' }}>
            {`Antes: ${product.data.cost} ${getCurrencyTypeDescription(product.data.currency)}`}
          </span>
          {bestOffer.offerType !== 'productQuantity' ? (
            <span>
              {`Ahora: ${lowestPrice.toFixed(2)} ${getCurrencyTypeDescription(product.data.currency)}`}
            </span>
          ) : (
            <span>
              {`${bestOffer.offerValue} % menos en el ${bestOffer.productQuantity} productos`}
            </span>
          )}
        </>
      );
    } else {
      // Render regular price if no offer
      return (
        <span>{`Precio: ${product.data.cost} ${getCurrencyTypeDescription(product.data.currency)}`}</span>
      );
    }
  };

  const productsInOffer = products.list.filter(product => {
    const { bestOffer } = calculateBestOffer(product);
    return bestOffer && (bestOffer.applicability !== 'vip' || currentUser.vip);
  });

  const carouselOptions = {
    autoPlay: true,
    autoPlayInterval: 3000,
    mouseTracking: true,
    disableDotsControls: true,
    disableButtonsControls: false,
    responsive: {
      0: { items: 1 },
      700: { items: 2 },
      1024: { items: 5, itemsFit: 'contain' }
    }
  };

  return (
    <div className="home">
      {/* <Carousel autoplay interval={4000} className="cover-carousel">
        {coverCarousel.map((imageId, index) => (
          <img
            key={`img${imageId}`}
            src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fhome%2F${imageId}?alt=media`}
            alt={`Cover Image`}
          />
        ))}
      </Carousel> */}
      {coverCarousel.length ? (
        <div className="cover-carousel">
          <img
            className="cover-image"
            src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fhome%2F${
              coverCarousel[Math.random() * coverCarousel.length | 0]
            }?alt=media`}
            alt={`Cover Image`}
          />
        </div>
      ) : null}

      <h2 style={{ textAlign: 'center', margin: '20px 0' }}>Novedades</h2>

      <AliceCarousel {...{ ...carouselOptions, infinite: true }} className="alice-carousel">
        {Object.values(homeProducts).map(product => (
          <div
            key={`homep-${product.id}`}
            className="product-card"
            onClick={() => openProductModal(product)}
            style={{ cursor: 'pointer', margin: '20px' }}
          >
            <div className="product-image">
              <img
                key={`imgc-${product.id}`}
                src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fproducts%2Fthumb%2F${product.image}?alt=media`}
                alt={`Product ${product.id}`}
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
            </div>
            <div className="product-details">
              <h4>{product.title}</h4>
            </div>
          </div>
        ))}
      </AliceCarousel>
      {window.configClientKey === 'nea' && (
        <blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@nea_bolivia"
          data-unique-id="nea_bolivia"
          data-embed-from="embed_page"
          data-embed-type="creator"
          style={{ maxWidth: 780, minWidth: 288 }}
        >
          <section>
            <a
              target="_blank"
              href="https://www.tiktok.com/@nea_bolivia?refer=creator_embed"
            >
              @nea_bolivia
            </a>
          </section>
        </blockquote>
      )}
      {productsInOffer.length > 0 &&(
        <>
          <h2 style={{ textAlign: 'center', margin: '20px 0' }}>Ofertas</h2>

      <AliceCarousel {...carouselOptions} className="alice-carousel">
        {productsInOffer.map(product => {
          const { bestOffer, lowestPrice } = calculateBestOffer(product);
          const hasOffer = product.data.offers && Object.keys(product.data.offers).length > 0;
          let showOfferBadge = hasOffer;

          if (hasOffer) {
            showOfferBadge = Object.values(product.data.offers).some(offer => {
              return offer.applicability !== 'vip' || currentUser.vip;
            });
          }

          return (
            <div
              key={`offer-${product.id}`}
              className="mb-item"
              onClick={() => onClickProduct(product.id)}
              style={{ cursor: 'pointer' }}
            >
              <div className="mb-item-image">
                <Carousel
                  className="previews-slider"
                  shape="bar"
                  autoplay
                >
                  {product.data.images.map((imgId) => (
                    <img
                      key={imgId}
                      src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fproducts%2Fthumb%2F${imgId}?alt=media`}
                      alt={product.data.name}
                      className="mb-item-image-element"
                    />
                  ))}
                </Carousel>
                {showOfferBadge && (
                  <div className="offer-badge">Oferta</div>
                )}
                <button
                  className={`heart-button ${products.favorites.includes(product.id) ? 'filled' : ''}`}
                  onClick={(e) => toggleFavoriteClick(product, e)}
                >
                  <i className="fas fa-heart"></i>
                </button>
              </div>
              <div className="mb-item-title">{product.data.name}</div>
                <div className="mb-item-prices">
                  {renderOfferPrices(product, bestOffer, lowestPrice)}
                </div>
                
                {(!product.data.variables || product.data.variables?.length <= 0) && (
                    <div className="mb-item-button">
                    {isProductInCart(product.id) ? (
                      <span> <i className="fas fa-shopping-cart"></i> En el carrito</span>
                    ) : (
                      <Button onClick={(e) => addProductClick(product, null, e)}>
                        <i className="fas fa-shopping-cart"></i> Añadir al carrito
                      </Button>
                    )}
                  </div>
              )}
              {(product.data.variables?.length > 0) && (
                <div className="mb-item-button">
                  {isProductInCart(product.id) ? (
                    <span> <i className="fas fa-shopping-cart"></i> En el carrito</span>
                  ) : (
                    <div style={{display:'flex', flexDirection:'row'}}>
                      {product.data.variables?.map((variant, index) => (
                        <div key={`var-${index}`}  className="mb-item-button">
                          {/* Botón para seleccionar la variante */}
                          <Button onClick={(e) => addProductClick(product,variant, e)}>
                          <i className="fas fa-shopping-cart"></i> {variant.name}
                        </Button>
                        </div>
                      ))}
                    </div>
                    
                  )}
                </div>
              )}
            </div>
          );
        })}
      </AliceCarousel>
        </>
      )}
      
      <Modal open={selectedProduct !== null} onClose={closeProductModal}>
        <Modal.Header>
          <Modal.Title>{selectedProduct?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: selectedProduct?.description }} />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
}

export default Home;
