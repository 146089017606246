/* @flow */

import c from '../constants';

const defaultState = {
  list: [],
};

export default function productTypes(state:Object = defaultState, action:Object = {}):Object {
  switch (action.type) {
    case c.UPDATE_PRODUCT_TYPES: {
      return {
        ...state,
        list: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
