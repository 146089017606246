/* @flow */

import c from '../constants';

const defaultState = {
  cart: {},
  user: null,
};

export default function account(state: Object = defaultState, action: Object = {}): Object {
  switch (action.type) {
    case c.SET_USER:
      return { ...state, user: action.payload };
    case c.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case c.SIGN_OUT:
      return {
        ...state,
        user: null,
      };
      case c.UPDATE_CART: {
        return {
          ...state,
          cart: action.payload,
        };
      }
      case c.ADD_PRODUCT_TO_CART: {
        const { id, quantity } = action.payload;
        const updatedCart = {
          ...state.cart,
          [id]: { quantity }, 
        };
        return {
          ...state,
          cart: updatedCart,
        };
      }
      case c.REMOVE_PRODUCT_TO_CART: {
        const updatedCart = { ...state.cart };
        delete updatedCart[action.payload.id]; 
        return {
          ...state,
          cart: updatedCart,
        };
      }
      case c.CLEAR_CART: {
        return {
          ...state,
          cart: {},
        };
      }
    default:
      break;
  }
  return state;
}
