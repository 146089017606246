/* @flow */

import c from '../constants';

const defaultState = {
  list: [],
  favorites: [], 
  comments: {},
  orders: [],
  currProduct: null,
};

export default function products(state:Object = defaultState, action:Object = {}):Object {
  switch (action.type) {
    case c.UPDATE_PRODUCTS: {
      if (action.add) {
        return {
          ...state,
          list: state.list.concat(action.payload),
        };
      }
      return {
        ...state,
        list: action.payload,
      };
    }
    case c.UPDATE_PRODUCT: {
      return {
        ...state,
        currProduct: action.payload,
      };
    }
    case c.UPDATE_COMMENTS: {
      let arrayComments = state.comments[action.payload.id] || [];
      const arr = action.payload.comments.filter((c) => {
        const aux = arrayComments.find((ac) => (
          ac.id === c.id
        ));
        return aux == null;
      });
      arrayComments = [...arrayComments, ...arr];
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.id]: arrayComments,
        },
      };
    }
    case c.DELETE_COMMENT: {
      const { id, commentId } = action.payload;
      const updatedComments = state.comments[id]?.filter(comment => comment.id !== commentId) || [];
      
      return {
        ...state,
        comments: {
          ...state.comments,
          [id]: updatedComments,
        },
      };
    }
   
    case c.UPDATE_FAVORITES: { 
      return {
        ...state,
        favorites: action.payload,
      };
    }
   
    case c.ADD_PRODUCT_TO_FAVORITES: 
      return {
        ...state,
        favorites: state.favorites.concat(action.payload.id),
      };
    case c.REMOVE_PRODUCT_TO_FAVORITES: 
      return {
        ...state,
        favorites: state.favorites.filter((id) => id !== action.payload.id),
      };
      case c.ADD_ORDER: {
        return {
          ...state,
          orders: [...state.orders, action.payload], 
        };
      }
     
      case c.UPDATE_ORDERS: { 
        return {
          ...state,
          orders: action.payload,
        };
      }
      case c.UPDATE_ORDER: {
        const { id, data } = action.payload; 
        const updatedOrders = state.orders.map(order => {
          if (order.id === id) {
            return {
              id,
              data: {
                ...order.data,
                ...data, 
              },
            };
          }
          return order; 
        });
      
        return {
          ...state,
          orders: updatedOrders,
        };
      }
    
    default:
      return state;
  }
}