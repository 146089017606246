/* @flow */

import { combineReducers } from 'redux';

import account from './account';
// import stores from './stores';
import products from './products';
import branches from './branches';
import tags from './tags';
import productTypes from './productTypes';
import client from './client';

const rootReducer:any = combineReducers({
  account,
  client,
  // stores,
  products,
  branches,
  tags,
  productTypes,
});

export default rootReducer;
