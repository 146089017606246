/* @flow */

import c from '../constants';

const defaultState = {
  list: [],      
  currBranch: null,
};

export default function branches(state:Object = defaultState, action:Object = {}):Object {
  switch (action.type) {
    case c.UPDATE_BRANCHES: { 
      return {
        ...state,
        list: action.payload,
      };
    }
    case c.ADD_BRANCH: { 
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }
    case c.UPDATE_BRANCH: { 
        return {
          ...state,
          currBranch: action.payload,
        };
      }
    case c.REMOVE_BRANCH: { 
      return {
        ...state,
        list: state.list.filter(branch => branch.id !== action.payload.id),
      };
    }
    default:
      return state;
  }
}
