/* @flow */

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'rsuite/Table';
import Input from 'rsuite/Input';
import Panel from 'rsuite/Panel';
import Button from 'rsuite/Button';
import SelectPicker from 'rsuite/SelectPicker';

import { getCashRegister, openCashRegister, closeCashRegister} from '../actions/cashRegister';
import { getSalesWithIds } from '../actions/sale';
const { Column, HeaderCell, Cell } = Table;

const CashRegister = () => {
  const dispatch = useDispatch();
  const branches = useSelector((state) => state.branches.list);
  const branchesArray = useMemo(() => {
    return branches?.map(item => {
      return {
        label: item.data.name,
        value: item.id,
      };
    });
  }, [branches?.length]);
  const [branchSelect, setBranchSelect] = useState(branches[0]?.id);
  const cashRegister = useSelector(state => state.client.cashRegister || {}) ;
  const [amount, setAmount] = useState(cashRegister[branches[0]?.id]?.amount || 0);
  const [isOpen, setIsOpen] = useState(cashRegister[branches[0]?.id]?.isOpen || false);
  const [isEditable, setIsEditable] = useState(false);
  const [sales, setSales] = useState(
    cashRegister[branches[0]?.id]?.sales || []
  );
  const users = useSelector(state => state.client.users);
  
  useEffect(() => {
    if(!cashRegister){
        dispatch(getCashRegister());
        setBranchSelect(branches[0]?.id);
        setAmount(cashRegister[branches[0]?.id]?.amount || 0);
        setIsOpen(cashRegister[branches[0]?.id]?.isOpen || false);
        setSales(cashRegister[branches[0]?.id]?.sales || []);
    }
    if (cashRegister[branches[0]?.id]?.sales && cashRegister[branches[0]?.id]?.sales.length > 0) {
      dispatch(getSalesWithIds(cashRegister[branches[0]?.id].sales)).then(fetchedSales => {
        setSales(fetchedSales);
      });
    }
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
    setIsEditable(true);
  };

  const handleSave = () => {
    dispatch(openCashRegister(amount, branchSelect));
    setIsEditable(false);
    setSales([]);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsEditable(false);
    dispatch(closeCashRegister(branchSelect));
  };

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return '';
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    return date.toLocaleString();
  };

  const getUserEmailOrName = (userId) => {
    const user = users.find(user => user.id === userId);
    if (user) {
      const { email, fullName } = user.data;
      return fullName || email || 'Desconocido';
    }
    return 'Desconocido';
  };
  const handleChangeBranch = (branchId)=> {
    setBranchSelect(branchId);
    if(branchId){
      setAmount(cashRegister[branchId]?.amount || 0);
      setIsOpen(cashRegister[branchId]?.isOpen || false);
      setSales(cashRegister[branchId]?.sales || []);
      if (cashRegister[branchId]?.sales.length > 0) {
        dispatch(getSalesWithIds(cashRegister[branchId]?.sales)).then(fetchedSales => {
          setSales(fetchedSales);
        });
      }
    }
    
  }
  
  return (
    <div>
       <SelectPicker
                  placeholder={"Sucursal"}
                  value={branchSelect}
                  data={branchesArray}
                  onChange={(value) => {handleChangeBranch(value)} }
                />
      {!isOpen ? (
        <>
            <Button onClick={handleOpen} appearance="primary">Abrir Caja</Button>
            <h5>Monto final en caja: {amount} Bs</h5>
        </>
      ) : (
        <div>
          <h2>Monto en Caja</h2>
          <Input
            value={amount}
            onChange={value => setAmount(value)}
            min={0}
            disabled={!isEditable}
          />
         {isEditable &&  <Button onClick={handleSave} appearance="primary">Guardar</Button>}
         {!isEditable && <Button onClick={handleClose} appearance="subtle">Cerrar Caja</Button>}
        </div>
      )}
       <Panel header="Historial de ventas" collapsible bordered>
       <Table
          data={sales}
          autoHeight
          wordWrap="break-word"
          rowHeight={70}
          headerHeight={70}
        >
          <Column width={150} align="center" >
            <HeaderCell>Fecha</HeaderCell>
            <Cell>
              {rowData => formatDate(rowData.data?.date)}
            </Cell>
          </Column>
  
          <Column width={150} align="center" >
            <HeaderCell>Cliente</HeaderCell>
            <Cell>
              {rowData => getUserEmailOrName(rowData.data?.userId)}
            </Cell>
          </Column>
  
          <Column width={100} align="center" >
            <HeaderCell>Cantidad de Productos</HeaderCell>
            <Cell>
              {rowData => rowData.data?.products.length}
            </Cell>
          </Column>

          <Column width={100} align="center">
            <HeaderCell>Total Venta</HeaderCell>
            <Cell dataKey="data.total" />
          </Column>

          <Column width={200} align="center" >
            <HeaderCell>Deuda</HeaderCell>
            <Cell>
              {rowData => <span>{rowData.data?.due}</span>}
            </Cell>
          </Column>

          <Column width={200} align="center" >
            <HeaderCell>Total a caja</HeaderCell>
            <Cell>
              {rowData => (parseFloat(rowData.data?.total) - parseFloat(rowData.data?.due)).toFixed(2)}
            </Cell>
          </Column>
        </Table>
        </Panel>
    </div>
  );
};

export default CashRegister;
