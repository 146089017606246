/* @flow */

import React, { useState, useEffect } from 'react';

import Button from 'rsuite/Button';
import Form from 'rsuite/Form';
import ButtonToolbar from 'rsuite/ButtonToolbar';
import Message from 'rsuite/Message';
import Nav from 'rsuite/Nav';

import VisibleIcon from '@rsuite/icons/Visible';
import UnvisibleIcon from '@rsuite/icons/Unvisible';

import classNames from 'classnames';
import {
  createEmailPass,
  signInEmailPass,
} from '../actions/account';
import { useNavigate } from 'react-router-dom';

import './Enter.css';

function Login(props: Object):Object {
  let navigate = useNavigate();
  const [loginActive, setLoginActive] = useState(false);
  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });
  const [errorCreateMsg, setErrorCreateMsg] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  function onClickGoToSignIn() {
    setLoginActive(true);
  }
  function onClickGoToCreate() {
    setLoginActive(false);
  }
  function onCreateAccount() {
    createEmailPass(formValue.email, formValue.password)
      .then((user) => {
        navigate('/');
      })
      .catch((error) => {
        let errorMsg = 'Error';
        if (error.code === 'auth/email-already-in-use') {
          errorMsg = 'The email address is already in use.';
        } else if (error.code === 'auth/invalid-email') {
          errorMsg = 'The email address is not valid.';
        } else if (error.code === 'auth/operation-not-allowed') {
          errorMsg = 'Operation not allowed.';
        } else if (error.code === 'auth/weak-password') {
          errorMsg = 'The password is too weak.';
        }
        setErrorCreateMsg(window.lc(errorMsg));
      });
  }
  function onLoginAccount() {
    if (formValue.email && formValue.password) {
      signInEmailPass(formValue.email, formValue.password)
        .then((props:Object) => {
          if (props.user) {
            navigate('/');
          }
        })
        .catch((error) => {
          console.log('error', error);
          setErrorCreateMsg(error.message);
        });
    }
  }

  return (
    <div
      className={classNames('mb-enter', {
        login: loginActive,
        logon: !loginActive,
      })}
    >
      <div className="mb-mobile-tab">
        <Nav appearance="tabs" justified>
          <Nav.Item
            active={loginActive}
            onSelect={onClickGoToSignIn}
          >
            {window.lc('Sign In')}
          </Nav.Item>
          <Nav.Item
            active={!loginActive}
            onSelect={onClickGoToCreate}
          >
            {window.lc('Sign On')}
          </Nav.Item>
        </Nav>
      </div>
      <div className="mb-signin">
        <div className="inactive">
          <h4>{window.lc('Welcome Back!')}</h4>
          <Button onClick={onClickGoToSignIn}>
            {window.lc('Sign In')}
          </Button>
        </div>
        <div className="active">
          <h4>{window.lc('Sign In to {PageName}!')}</h4>
          <Form
            onChange={formValue => {
              setFormValue(formValue);
            }}
            style={{ marginTop: 10 }}
          >
            <Form.Group>
              <label>Email</label>
              <Form.Control name="email" type="email" />
              <Form.HelpText tooltip>
                {window.lc('Required')}
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <label>Password</label>
              <Form.Control
                name="password"
                type={passwordVisible ? 'text' : 'password'}
              />
              <a
                className="cl-visibility"
                onClick={() => {
                  setPasswordVisible(!passwordVisible);
                }}
              >
                {passwordVisible ? <VisibleIcon /> : <UnvisibleIcon />}
              </a>
              <Form.HelpText tooltip>
                {window.lc('Required')}
              </Form.HelpText>
            </Form.Group>
            {errorCreateMsg ? (
              <Message type="error">
                {errorCreateMsg}
              </Message>
            ) : null}
            <Form.Group className="button-center">
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  onClick={onLoginAccount}
                >
                  {window.lc('Enter')}
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="mb-signup">
        <div className="active">
          <h4>{window.lc('Create Account')}</h4>
          <div className="create-desc">
            <div>Crea tu cuenta y comienza a comprar!</div>
            <div>Registrarte es simple y tarda solo minutos!</div>
          </div>
          <Form
            onChange={formValue => {
              setFormValue(formValue);
            }}
            style={{ marginTop: 10 }}
          >
            <Form.Group>
              <label>Email</label>
              <Form.Control name="email" type="email" />
              <Form.HelpText tooltip>
                {window.lc('Required')}
              </Form.HelpText>
            </Form.Group>
            <Form.Group>
              <label>Password</label>
              <Form.Control
                name="password"
                type={passwordVisible ? 'text' : 'password'}
              />
              <a
                className="cl-visibility"
                onClick={() => {
                  setPasswordVisible(!passwordVisible);
                }}
              >
                {passwordVisible ? <VisibleIcon /> : <UnvisibleIcon />}
              </a>
              <Form.HelpText tooltip>
                {window.lc('Required')}
              </Form.HelpText>
            </Form.Group>
            {errorCreateMsg ? (
              <Message type="error">
                {errorCreateMsg}
              </Message>
            ) : null}
            <Form.Group className="button-center">
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  onClick={onCreateAccount}
                >
                  {window.lc('Create')}
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </div>
        <div className="inactive">
          <h4>{window.lc('Hello, Friend!')}</h4>
          <Button
            onClick={onClickGoToCreate}
          >
            {window.lc('Create Account')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
