/* @flow */

export const getCurrencyTypeDescription = (code:number):string => {
  const currencyTypes = {
    0: 'Bs',
    1: '$',
  };

  return currencyTypes[code] || 'Bs'; 
};
  