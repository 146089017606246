/* @flow */

import { getConfig } from './config';

const localization = {
  'Account': 'Cuenta',
  'Admin': 'Admin',
  'Brand': 'Marca',
  'Cancel': 'Cancelar',
  'Cart': 'Carrito',
  'Create Account' : 'Crear Cuenta',
  'Create Branch': 'Crear Sucursal',
  'Create Suppliers': 'Crear Proveedores',
  'Create Brands': 'Crear Marcas',
  'Create Product': 'Crear Producto',
  'Create Product Types': 'Crear Tipos de Producto',
  'Delete': 'Eliminar',
  'Description': 'Descripcion',
  'Enter': 'Entrar',
  'Favorites': 'Favoritos',
  'Home': 'Inicio',
  'Hello, Friend!' : ' Eres nuevo?',
  'My Orders': 'Mis Ordenes',
  'Name': 'Nombre',
  'No Especificar Costo': 'No Especificar Costo',
  'Ok': 'Ok',
  'Orders': 'Ordenes',
  'Points': 'Puntos',
  'Preview': 'Vista Previa',
  'Required': 'Requerido',
  'Save': 'Guardar',
  'Search': 'Buscar',
  'Select a Brand': 'Selecciona una Marca',
  'Select a Currency': 'Selecciona una Moneda',
  'Select a Delivery': 'Selecciona un Tipo de Entrega',
  'Select a Type': 'Selecciona un Tipo',
  'Select Payment Type': 'Selecciona un Tipo de Pago',
  'Select Tags': 'Selecciona Tags',
  'Sign In': 'Iniciar Sesion',
  'Sign In to {PageName}!': 'Inicia Sesion en {PageName}!',
  'Sign Out': 'Cerrar Sesion',
  'Statistics': 'Estadisticas',
  'Title': 'Titulo',
  'Type': 'Tipo',
  'Upload Avatar': 'Subir Avatar',
  'Upload Photos': 'Subir Fotos',
  'Welcome Back!' : 'Ya tienes cuenta?',
  'Select A Category': 'Selecciona una Categoria',
  'Search Here': 'Busca Aqui',
};

export function formatDateToSpanish(date:Object):string {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return 'Hoy';
  }
  if (date.toDateString() === yesterday.toDateString()) {
    return 'Ayer';
  }
  const monthsInSpanish = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const month = monthsInSpanish[date.getMonth()];
  return `${date.getDate()} de ${month}`;
}

Object.keys(localization).forEach((k) => {
  localization[k] = localization[k].replaceAll('{PageName}', getConfig('name'));
});

window.lc = (key:string) => {
  return localization[key] || key;
};
