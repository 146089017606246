/* @flow */

import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';

import rootReducer from '../reducers';

const middlewares = [
  thunk,
];
if (process.env.NODE_ENV !== 'production') {
  // const logger = createLogger({ collapsed: true });
  // middlewares.push(logger);
}

// const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

export function configureStore(initialState) {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
        })
      : compose;
  const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
  );
  const store = createStore(rootReducer, initialState, enhancer);
  return store;
}
