/* @flow */

import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

import {
  documentId,
  query,
  where,
  or,
  collection,
  getDocs,
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = {
  apiKey: "AIzaSyCDwmZN2Wb7N-She9gqqri5c621igXF6Cw",
  authDomain: "appthapi-82dae.firebaseapp.com",
  projectId: "appthapi-82dae",
  storageBucket: "appthapi-82dae.appspot.com",
  messagingSenderId: "143530990663",
  appId: "1:143530990663:web:2e7be8e97cec32aaaba562",
  measurementId: "G-ZYDFYDZDF6",
};
const app = firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const firestore = firebase.firestore();
const storageRef = storage.ref();
const analytics = getAnalytics(app);

export const auth = firebase.auth();
auth.languageCode = 'es';

export function processSnapshotsArr(list: Array<Object>): Array<Object> {
  const res = [];
  list.forEach((doc: Object) => {
    res.push({
      id: doc.id,
      data: { ...doc.data() },
    });
  });
  return res;
}

export function onAuthStateChanged(cb: Function) {
  auth.onAuthStateChanged(async function(user) {
    if (user) {
      return await firestore.collection('users')
        .doc(user.uid)
        .get()
        .then((doc) => {
          let userRes = { uid: user.uid };
          if (doc.exists && user.uid) {
            userRes = { uid: user.uid, ...doc.data() };
          }
          if (userRes.birthdate && userRes.birthdate.toDate) {
            userRes.birthdate = userRes.birthdate.toDate();
          }
          window.userRes = userRes;
          cb(userRes);
        });
    }
  });
}

export function requestSaveAuthorData(userUid: string, props: Object) {
  Object.keys(props).forEach(k => {
    if (props[k] === undefined) {
      delete props[k];
    }
  });
  return firestore.collection('users')
    .doc(userUid)
    .set(props, { merge:true });
}
export function requestGetAuthorData(userUid: string): Promise<any> {
  const userDocRef = firestore.collection('users').doc(userUid);
  const clientUserDocRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(userUid);
  return Promise.all([
    userDocRef.get(),
    clientUserDocRef.get()
  ])
  .then(([userDoc, clientUserDoc]) => {
    let res = {};
    if (userDoc.exists) {
      res = { ...res, ...userDoc.data() };
      if (res.avatarId) {
        res.avatarUrl = `https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/users%2F${userUid}%2Favatar?alt=media`;
      }
    }
    if (clientUserDoc.exists) {
      res = { ...res, ...clientUserDoc.data() };
    }
    if (res.birthdate && res.birthdate.toDate) {
      res.birthdate = res.birthdate.toDate();
    }
    return res;
  })
  .catch((error) => {
    console.error("Error obteniendo datos del autor:", error);
    return {};
  });
}

export async function createUserWithEmailAndPassword(email: string, password: string): Promise<any> {
  const userDocRef = firestore.collection('clients').doc(window.configClientKey).collection('users');
  const userDocRootRef = firestore.collection('users');
  const querySnapshot = await userDocRef.where('email', '==', email).get();
  const batch = firestore.batch();

  try {
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);
    const user = userCredential.user;
    const userData = {
      email: user.email,
      admin: false,
      vip: false,
      superAdmin: false,
      hasAccount: true,
    };
    const newUserDocRootRef = userDocRootRef.doc(user.uid);

    if (!querySnapshot.empty) {
      const existingDoc = querySnapshot.docs[0];
      const existingDocRef = existingDoc.ref;
      const existingData = existingDoc.data();
      const { points, hasAccount, ...rootData } = existingData;
      batch.set(newUserDocRootRef, rootData, { merge: true });
      batch.delete(existingDocRef);
      const newUserDocRef = userDocRef.doc(user.uid);
      batch.set(newUserDocRef, { ...userData, points });
    } else {
      const newUserDocRef = userDocRef.doc(user.uid);
      batch.set(newUserDocRef, userData);
    }

    await batch.commit();

    return userCredential;
  } catch (error) {
    console.error("Error al crear usuario:", error);
    throw error;
  }
}

export function signInWithEmailAndPassword(email: string, password: string) {
  return auth.signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      // if (error.code === 'auth/invalid-credential') {
      //   error.message = 'Error al iniciar sesión (Usuario inexistente)';
      // }
      error.message = 'Error al iniciar sesión (password o usuario incorrecto)';
      throw error;
    });
}

export function signOutAuth() {
  return auth.signOut()
    .catch(function(error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      throw error;
    });
}

export async function requestCreateProductVisits(productId:string) {
  const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('product_visits');
  const productObj = await productsRef.doc(productId);
  return await productObj.set({
    count: 0,
  }, { merge: true });
}

export async function requestCreateProduct(props:Object):Object {
  try {
    const productId = uuidv4();
    const productsVisitsRef = firestore.collection('clients').doc(window.configClientKey).collection('product_visits');
    const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
    const tagsRef = firestore.collection('clients').doc(window.configClientKey).collection('tags');
    return firestore.runTransaction((transaction) => {
      const arrPromises = [];
      const productRef = productsRef.doc(productId);
      const productVRef = productsVisitsRef.doc(productId);

      props.tags.forEach((tag) => {
        const tagLower = tag
          .replaceAll(' ', '')
          .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const tagRef = tagsRef.doc(tagLower);
        arrPromises.push(
          transaction.get(tagRef).then((tagDoc) => {
            if (tagDoc.exists) {
              const tagObj = tagDoc.data();
              let newCount = tagObj.count + 1;
              const list = tagObj.list || [];
              if (productId) list.push(productId);
              transaction.update(tagRef, {
                count: newCount,
                list,
                last_date: new Date(),
              });
              return newCount;
            }
            const list = [];
            if (productId) list.push(productId);
            transaction.set(tagRef, {
              value: tag,
              list,
              count: 1,
              last_date: new Date(),
            });
            return 1;
          })
        );
      });

      transaction.set(productRef, props);
      transaction.set(productVRef, {
        count: 0,
        whatsapp_count: 0,
      });
      return Promise.all(arrPromises).then(() => {
        return {
          productId,
        };
      });
    });
  } catch (e) {
    console.error('Transaction failed:', e);
    return e;
  }
}

export async function requestCreateMultipleProducts(arr:Object) {
  try {
    const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
    const tagsRef = firestore.collection('clients').doc(window.configClientKey).collection('tags');
    return firestore.runTransaction((transaction) => {
      const arrPromises = [];
      const fns = [];
      const fnsTagSet = {};
      const fnsTagUpd = {};
      const fnsTagUpdCount = {};
      const tagsProd = {};
      const productIds = [];

      arr.forEach((props, index) => {
        const productId = uuidv4();
        productIds[index] = productId;
        props.tags && props.tags.forEach((tag) => {
          const tagLower = tag
            .replaceAll(' ', '')
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
          tagsProd[tagLower] = tagsProd[tagLower] || [];
          tagsProd[tagLower].push(productId);
        });
      });

      Object.keys(tagsProd).forEach((tagLower) => {
        const tagRef = tagsRef.doc(tagLower);
        arrPromises.push(
          transaction.get(tagRef).then((tagDoc) => {
            if (tagDoc.exists) {
              const tagObj = tagDoc.data();
              fnsTagUpdCount[tagLower] = tagObj.count;
              fnsTagUpd[tagLower] = tagsProd[tagLower];
            } else {
              fnsTagSet[tagLower] = tagsProd[tagLower];
            }
            return 1;
          })
        );
      });

      arr.forEach((props, index) => {
        const productId = productIds[index];
        fns.push(() => {
          transaction
            .set(productsRef.doc(productId), props);
        });
      });

      return Promise.all(arrPromises).then(() => {
        Object.keys(fnsTagSet).forEach((tag) => {
          transaction.set(tagsRef.doc(tag), {
            value: tag,
            list: fnsTagSet[tag],
            count: fnsTagSet[tag].length,
            last_date: new Date(),
          });
        });
        Object.keys(fnsTagUpd).forEach((tag) => {
          transaction.set(tagsRef.doc(tag), {
            list: fnsTagUpd[tag],
            count: fnsTagUpd[tag].length + fnsTagUpdCount[tag],
            last_date: new Date(),
          });
        });
        fns.forEach((fn) => fn());
        return {};
      });
    });
  } catch (e) {
    console.error('Transaction failed:', e);
    return e;
  }
}

export async function updateMultipleProducts() {
  try {
    const productsRef = firestore.collection('clients')
      .doc(window.configClientKey)
      .collection('products');
    return firestore.runTransaction((transaction) => {
      return productsRef.get().then((querySnapshot) => {
        const arr = processSnapshotsArr(querySnapshot);
        arr.forEach((doc) => {
          if (
            doc.data.tags &&
            doc.data.tags.length > 0 &&
            !doc.data.tagsObj
          ) {
            const newTagsObj = {};
            doc.data.tags.forEach(t => { newTagsObj[t] = true });
            transaction.update(productsRef.doc(doc.id), {
              tagsObj: newTagsObj,
            });
          }
        });
      });
    });
  } catch (e) {
    console.error('Transaction failed:', e);
    return e;
  }
}
window.updateMultipleProducts = updateMultipleProducts;

export async function getProductsLength() {
  const productsRef = firestore.collection('clients')
    .doc(window.configClientKey)
    .collection('products');
  return productsRef.get().then((querySnapshot) => {
    return querySnapshot.size;
  });
}
window.getProductsLength = getProductsLength;

export async function requestUpdateProduct(productId:string, props:Object) {
  try {
    const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
    const tagsRef = firestore.collection('clients').doc(window.configClientKey).collection('tags');
    return firestore.runTransaction((transaction) => {
      const arrPromises = [];
      const productRef = productsRef.doc(productId);

      props.tags.forEach((tag) => {
        const tagLower = tag
          .replaceAll(' ', '')
          .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const tagRef = tagsRef.doc(tagLower);
        arrPromises.push(
          transaction.get(tagRef).then((tagDoc) => {
            if (tagDoc.exists) {
              const tagObj = tagDoc.data();
              let newCount = tagObj.count + 1;
              const list = tagObj.list || [];
              if (productId && !list.includes(productId)) list.push(productId);
              transaction.update(tagRef, {
                count: newCount,
                list,
                last_date: new Date(),
              });
              return newCount;
            }
            const list = [productId];
            transaction.set(tagRef, {
              value: tag,
              list,
              count: 1,
              last_date: new Date(),
            });
            return 1;
          })
        );
      });

      transaction.set(productRef, props ,{ merge: true });
      return Promise.all(arrPromises).then(() => {
        return {
          productId,
        };
      });
    });
  } catch (e) {
    console.error('Transaction failed:', e);
    return e;
  }
}

export function uploadFirebaseImage(file: Object, props: Object) {
  let id = uuidv4();
  if (props.forceId) id = props.forceId;
  const imagesRef = storageRef.child(`${props.folder}/${id}`);
  const uploadTask = imagesRef.put(file);
  return new Promise(resolve => {
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (props.progressFn) props.progressFn(progress);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      (error) => { // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },
      () => {
        resolve(id);
      }
    );
  });
}

export async function requestGetProducts(
  props:Object,
  startAfterDate:any = null
):Promise<any> {
  const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
  if (props?.barcode != null) {
    return await getDocs(
      query(collection(firestore, `clients/${window.configClientKey}/products`),
        or(
          where('barcodes', 'array-contains', props.barcode)
        )
      )
    );
  }
  if (props?.sku != null) {
    return await getDocs(
      query(collection(firestore, `clients/${window.configClientKey}/products`),
        or(
          where('skus', 'array-contains', props.sku),
        )
      )
    );
  }
  let productsQuery = productsRef;
  if (props?.type != null) {
    productsQuery = productsQuery.where('type', '==', props.type);
  }
  if (props?.tags && props.tags.length > 0) {
    productsQuery = productsQuery.where('tags', 'array-contains-any', props.tags);
  }
  if (props?.branch != null) {
    productsQuery = productsQuery.where(`branches`, '!=', null);
  }
  if (startAfterDate) {
    return await productsQuery
      .orderBy('date', 'desc')
      .startAfter(startAfterDate)
      .limit(props.limit || 25)
      .get();
  }
  return await productsQuery
    .orderBy('date', 'desc')
    .limit(props.limit || 25)
    .get();
}

export async function requestGetProduct(productId:string):Promise<any> {
  const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
  return await productsRef.doc(productId).get();
}

export async function requestGetProductComments(
  productId:string,
  props:Object
):Promise<any> {
  const commentsRef = firestore.collection('clients')
    .doc(window.configClientKey).collection('products')
    .doc(productId).collection('comments');
  const querySnapshot = await commentsRef.limit(10).get();
  return processSnapshotsArr(querySnapshot);
}

export async function requestAddProductComment(
  productId:string,
  comment:string,
  rating:string,
):Promise<any> {
  const commentId = uuidv4();
  const commentRef = firestore.collection('clients')
    .doc(window.configClientKey).collection('products')
    .doc(productId).collection('comments').doc(commentId);
  const newData = {
    userId: window.userRes.uid,
    userName: window.userRes.name,
    comment,
    rating,
    timestamp: new Date(),
  };
  await commentRef.set(newData, { merge:true });
  return {
    id: commentId,
    data: newData,
  };
}

export async function requestDeleteProductComment(productId, commentId) {
  try {
    const commentRef = firestore
      .collection('clients')
      .doc(window.configClientKey)
      .collection('products')
      .doc(productId)
      .collection('comments')
      .doc(commentId);

    await commentRef.delete();

    return { productId, commentId };
  } catch (error) {
    console.error('Error deleting comment:', error);
    throw error;
  }
}
export async function requestGetTags():Promise<any> {
  const tagsRef = firestore.collection('clients').doc(window.configClientKey).collection('tags');
  return await tagsRef
    .orderBy('count', 'desc')
    .limit(150)
    .get();
}

export async function requestGetTypes():Promise<any> {
  const typesRef = firestore.collection('clients').doc(window.configClientKey).collection('types');
  return await typesRef
    .orderBy('count', 'desc')
    .limit(150)
    .get();
}

export async function requestGetBrands():Promise<any> {
  const brandsRef = firestore.collection('clients').doc(window.configClientKey).collection('brands');
  return await brandsRef
    .orderBy('count', 'desc')
    .limit(150)
    .get();
}

export async function requestGetSuppliers():Promise<any> {
  const suppliersRef = firestore.collection('clients').doc(window.configClientKey).collection('suppliers');
  return await suppliersRef
    .orderBy('count', 'desc')
    .limit(150)
    .get();
}

export async function requestAddTypes(newTypes:Array<string>):Promise<any> {
  const batch = firestore.batch();
  const typesRef = firestore.collection('clients').doc(window.configClientKey).collection('types');
  newTypes.forEach((typeStr) => {
    const typeId = uuidv4();
    batch.set(typesRef.doc(typeId), {
      count: 0,
      name: typeStr,
    });
  });
  await batch.commit();
}

export async function requestAddBrands(newBrands:Array<string>):Promise<any> {
  const batch = firestore.batch();
  const brandsRef = firestore.collection('clients').doc(window.configClientKey).collection('brands');
  newBrands.forEach((brandStr) => {
    const brandId = uuidv4();
    batch.set(brandsRef.doc(brandId), {
      count: 0,
      name: brandStr,
    });
  });
  await batch.commit();
}

export async function requestAddSuppliers(newSuppliers:Array<string>):Promise<any> {
  const batch = firestore.batch();
  const suppliersRef = firestore.collection('clients').doc(window.configClientKey).collection('suppliers');
  newSuppliers.forEach((suppliersStr) => {
    const suppliersId = uuidv4();
    batch.set(suppliersRef.doc(suppliersId), {
      count: 0,
      name: suppliersStr,
    });
  });
  await batch.commit();
}

export async function requestAddProductToFavorites(
  productId: string
): Promise<any> {
    const idUser = window.userRes.uid;
  const favoritesRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(idUser).collection('favorites').doc(productId);
 
  const newData = {
    timestamp: new Date(),
  };
  await favoritesRef.set(newData, { merge:true });
  return {
    id: productId,
    data: newData,
  };
}

export function requestRemoveProductFromFavorites(productId: string): Promise<any> {
  const idUser = window.userRes?.uid;

  const userRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(idUser);
  const favoritesRef = userRef.collection('favorites').doc(productId);

  return favoritesRef.delete()
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      console.error('Error removing product from favorites:', error);
      throw { message: 'Failed to remove product from favorites' };
    });
}

export async function requestGetUserFavorites(): Promise<any> {
  const idUser = window.userRes.uid;
  const favoritesRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(idUser).collection('favorites');
  const querySnapshot = await favoritesRef.get();
  
  const favorites = [];
  querySnapshot.forEach((doc) => {
    favorites.push(doc.id);
  });
  
  return favorites;
}

export async function requestSetProductToCart(
  cartData:Object = {}
):Promise<any> {
  const userRef = firestore.collection('clients').doc(window.configClientKey)
    .collection('users')
    .doc(window.userRes?.uid);
  return userRef.set({ cart: cartData }, { merge: true });
}

export async function requestRemoveProductFromCart(productId): Promise<any> {
  const idUser = window.userRes?.uid;
  const cartRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(idUser);

  try {
    const doc = await cartRef.get();
    const cartData = doc.data().cart || {};
    delete cartData[productId];
    await cartRef.update({ cart: cartData });
    return { id: productId };
  } catch (error) {
    console.error('Error removing product from cart:', error);
    throw { message: 'Failed to remove product from cart' };
  }
}

export async function requestRemoveVariantFromCart(productId: string, variant: any): Promise<any> {
  const idUser = window.userRes?.uid;
  const cartRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(idUser);

  try {
    const doc = await cartRef.get();
    const cartData = doc.data().cart || {};

    if (cartData[productId]) {
      delete cartData[productId][variant];
      if (Object.keys(cartData[productId]).length === 0) {
        delete cartData[productId];
      }
      await cartRef.update({ cart: cartData });
    }

    return { ...cartData };
  } catch (error) {
    console.error('Error removing variant from cart:', error);
    throw { message: 'Failed to remove variant from cart' };
  }
}


export function requestCreateBranch(branchData: Object): Promise<any> {
  const branchId = uuidv4();
  const branchRef = firestore.collection('clients')
    .doc(window.configClientKey)
    .collection('branches')
    .doc(branchId);

  return branchRef
    .set({
      ...branchData,
    })
    .then(() => {
      return {
        id: branchId,
        data: {
          ...branchData,
        },
      };
    });
}

export async function requestGetBranches()  : Promise<any>{
  const branchesRef = firestore.collection('clients').doc(window.configClientKey).collection('branches');
  const querySnapshot = await branchesRef.get();

  const branches = [];
  querySnapshot.forEach((doc) => {
    branches.push({
      id: doc.id,
      data: doc.data(),
    });
  });

  return branches;
}

export async function requestAddOrder(order) {
  try {
    const userId = window.userRes.uid; 
    const orderId = uuidv4();
    const ordersRef = firestore.collection('clients').doc(window.configClientKey).collection('orders');
    const ordersSnapshot = await ordersRef.get();
    const orderCount = ordersSnapshot.size; 
    const orderNumber = `P${orderCount + 1}`;

    const newOrder = {
      ...order,
      orderNumber: orderNumber,
      userId: userId,
      state: "En cola",
      timestamp: new Date(),
      changes: [{
        userId: userId,
        date: new Date(),
        message: 'Se añadió a la cola',
      }],
    };

    const batch = firestore.batch();
    const userRef = firestore.collection('clients').doc(window.configClientKey)
      .collection('users')
      .doc(userId)
    batch.update(userRef, { cart: {} });
    batch.set(ordersRef.doc(orderId), newOrder);
    await batch.commit();

    return { id: orderId, data: newOrder };
  } catch (error) {
    console.error('Error al añadir el pedido y vaciar el carrito:', error);
    throw error;
  }
}

export async function requestGetOrders() {
  const ordersRef = firestore.collection('clients').doc(window.configClientKey).collection('orders');
  const querySnapshot = await ordersRef.get();
  return processSnapshotsArr(querySnapshot);
}

export async function requestGetUserOrders() {
  const uid = window.userRes.uid; 
  const ordersRef = firestore.collection('clients').doc(window.configClientKey).collection('orders');
  const querySnapshot = await ordersRef.where('userId', '==', uid).get(); 
  return processSnapshotsArr(querySnapshot);
}

export async function requestUpdateOrder(updatedOrder, orderId) {
  try {
    const uid = window.userRes.uid; 
    const orderRef = firestore.collection('clients').doc(window.configClientKey).collection('orders').doc(orderId);
    const newChange = {
      userId: uid, 
      date: new Date(),
      message: `Actualizado a ${updatedOrder.state}`
    };
    const updatedOrderData = {
      ...updatedOrder,
      changes: [...(updatedOrder.changes || []), newChange] 
    };

    await orderRef.set(updatedOrderData);

    return {id: orderId, data: updatedOrderData}; 
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
}

export async function requestUpdateStateOrder(orderId, newState) {
  try {
    const uid = window.userRes.uid; 
    const orderRef = firestore.collection('clients').doc(window.configClientKey).collection('orders').doc(orderId);
    const orderSnapshot = await orderRef.get();
    const orderData = orderSnapshot.data();
    const newChange = {
      userId: uid, 
      date: new Date(), 
      message: `Actualizado a ${newState}`
    };
    await orderRef.update({
      state: newState,
      changes: [...(orderData.changes || []), newChange] 
    });

    return {
      id: orderId,
      data: {
        newState,
        changes: [...(orderData.changes || []), newChange] 
      }
    };
  } catch (error) {
    console.error('Error updating order state:', error);
    throw error;
  }
}


export const subscribeToOrderChanges = (callback:Function) => {
  return firestore.collection('clients').doc(window.configClientKey).collection('orders').onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      callback({
        id: change.doc.id,
        data: change.doc.data(),
        type: change.type,
      });
    });
  });
};


export async function requestGetProductsByList(productList): Promise<any> {
  const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
  try {
    return productsRef.where(documentId(), 'in', productList)
      .get()
      .then((productSnapshots) => {
        return processSnapshotsArr(productSnapshots);
      });
  } catch (error) {
    console.error('Error fetching products by list:', error);
    throw { message: 'Failed to fetch products by list' };
  }
}

export async function requestGetOrder(orderId: string): Promise<any> {
  try {
    const orderRef = firestore.collection('clients').doc(window.configClientKey).collection('orders').doc(orderId);
    const orderSnapshot = await orderRef.get();
    if (orderSnapshot.exists) {
      const orderData = orderSnapshot.data();
      return {
        data:orderData,
      };
    } else {
      throw new Error(`Order with ID ${orderId} does not exist.`);
    }
  } catch (error) {
    console.error('Error fetching order:', error);
    throw { message: 'Failed to fetch order', details: error.message };
  }
}

export function requestGetConfigs():Promise<any> {
  const docRef = firestore.collection('clients').doc(window.configClientKey);
  return docRef.get().then((doc) => {
    return doc.data();
  });
}

export function requestSetConfigs(
  configsData:Object = {}
):Promise<any> {
  const configRef = firestore.collection('clients').doc(window.configClientKey);
  return configRef.set({ configs: configsData }, { merge: true });
}

export async function requestGetUsers():Promise<any[]> {
  const usersCollectionRef = firestore.collection('clients').doc(window.configClientKey).collection('users');
  const usersCollectionRootRef = firestore.collection('users');

  try {
    // Obtener documentos de ambas colecciones simultáneamente
    const [usersSnapshot, rootUsersSnapshot] = await Promise.all([
      usersCollectionRef.get(),
      usersCollectionRootRef.get()
    ]);
    const rootUsersMap = {};
    rootUsersSnapshot.forEach(rootDoc => {
      rootUsersMap[rootDoc.id] = rootDoc.data();
    });

    // Crear una lista de usuarios combinando los datos de ambas colecciones
    const users = [];
    usersSnapshot.forEach(doc => {
      const rootUserData = rootUsersMap[doc.id] || {}; // Datos de la colección raíz, si existen
      const docData = { ...doc.data(), ...rootUserData }; // Combina los datos de ambas colecciones
      docData.hasAccount = docData.hasAccount || (
        rootUsersMap[doc.id] != null
      );
      users.push({
        id: doc.id,
        data: docData,
      });
    });

    return users;
  } catch (error) {
    console.error("Error obteniendo usuarios: ", error);
    throw error;
  }
}


export async function requestSetUserRole(userId: string, updatedRoles: Object): Promise<void> {
  const userRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(userId);
  return userRef.update({ ...updatedRoles });
}

export async function requestSetUserPoints(userId: string, totalPoints: number): Promise<void> {
  const userRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(userId);
  return userRef.update({ points:  totalPoints});
}

export async function requestSetNotUserPoints(email: string, pointsToAdd: number): Promise<void> {
  const usersRef = firestore.collection('clients').doc(window.configClientKey).collection('users');
  const querySnapshot = await usersRef.where('email', '==', email).get();
  const batch = firestore.batch();

  if (!querySnapshot.empty) {
    querySnapshot.docs.forEach(doc => {
      const currentPoints = parseFloat(doc.data().points) || 0; 
      batch.update(doc.ref, { points: currentPoints + pointsToAdd });
    });
  } else {
    const newDocRef = usersRef.doc(uuidv4()); 
    batch.set(newDocRef, { email, points: pointsToAdd, hasAccount: false  });
  }

  await batch.commit();
}

export async function requestSetProductToOffers(
  productId: string, offerInfo:object
):Promise<any> {
  const offerId = uuidv4();
  const offerRef = firestore.collection('clients').doc(window.configClientKey).collection('products').doc(productId)
  return offerRef.set({ offers:{[offerId]: offerInfo }}, { merge: true });
}

export async function requestGetProductsOnOffer(): Promise<any> {
  const productsRef = firestore.collection('clients').doc(window.configClientKey)
    .collection('products');
  try {
    // TODO: fix limit search condition not good
    const productSnapshots = await productsRef.limit(25).get();
    const allProducts = processSnapshotsArr(productSnapshots); 
    const productsOnOffer = allProducts.filter(product => {
      return product.data.offers &&
        Object.keys(product.data.offers).length > 0;
    });

    return productsOnOffer;
  } catch (error) {
    console.error('Error fetching products on offer:', error);
    throw { message: 'Failed to fetch products on offer' };
  }
}

export async function requestRemoveProductsToOffers(
  productId: string
):Promise<any> {
  const offerRef = firestore.collection('clients').doc(window.configClientKey).collection('products').doc(productId)
  return offerRef.set({ offers: {}}, { merge: true });
}

export async function requestRemoveProductToOffers(
  productId: string,
  offersData,
):Promise<any> {
  const offerRef = firestore.collection('clients').doc(window.configClientKey).collection('products').doc(productId)
  return offerRef.update({ offers: offersData},{ merge: true });
}

export async function requestCreateProductToCarousel(product: Object): Promise<any> {
  const productId = uuidv4();
  const productsRef = firestore.collection('clients').doc(window.configClientKey);

  try {
    await productsRef.set({
      home: {
        products: {
          [productId]: product
        }
      }
    }, { merge: true });

    return {
      ...product,
      id: productId 
    };

  } catch (error) {
    console.error('Error añadiendo producto al carrusel:', error);
    throw error;
  }
}

export function requestGetHome(): Promise<any> {
  const docRef = firestore.collection('clients').doc(window.configClientKey);
  return docRef.get().then((doc) => {
    if (doc.exists) {
      return doc.data().home;
    } else {
      console.warn('No such document!');
      return null;
    }
  }).catch((error) => {
    console.error('Error getting document:', error);
    throw error;
  });
}


export async function requestSetHome(
  homeData: Object
):Promise<any> {
  const offerRef = firestore.collection('clients').doc(window.configClientKey)
  return offerRef.update({ home: homeData}, { merge: true });
}

export async function requestCreateClient(newClient): Promise<any> {

  const batch = firestore.batch();
  const usersRef = firestore.collection('clients').doc(window.configClientKey).collection('users');
  const userRootRef = firestore.collection('users');
  const querySnapshot = await usersRef.where('email', '==', newClient.email).get();

  let clientDocId;
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    clientDocId = userDoc.id;
    newClient.hasAccount = true;
    batch.set(userRootRef.doc(clientDocId), {
      ...newClient
    },{merge: true});

  } else {
    clientDocId = uuidv4();
    batch.set(usersRef.doc(clientDocId), {
      ...newClient,
      hasAccount: false,
    },{merge: true});
  }
  await batch.commit();
  return {
    id: clientDocId,
    data: newClient
  };
} 


export const requestUpdateUser = async (userId, userData) => {
  try {
    const batch = firestore.batch();
    const usersRef = firestore.collection('clients').doc(window.configClientKey).collection('users').doc(userId);
    const userRootRef = firestore.collection('users').doc(userId);
    const userRootDoc = await userRootRef.get();

    if (userRootDoc.exists) {
      batch.set(userRootRef, userData, { merge: true });
    } else {
      batch.set(usersRef, userData, { merge: true });
    }
    await batch.commit();
  } catch (error) {
    console.error('Error updating user data:', error);
  }
};

export const requestDeleteClient = async (clientId): Promise<void> => {
  try {
    await firestore.collection('clients').doc(window.configClientKey).collection('users').doc(clientId).delete();
  } catch (error) {
    console.error('Error eliminando cliente:', error);
    throw error;
  }
};

function removeUndefinedFields(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export async function requestAddSale(saleData) {
  const batch = firestore.batch();
  const currentUser = window.userRes.uid;
  const usersRef = firestore.collection('clients').doc(window.configClientKey).collection('users');
  const salesRef = firestore.collection('clients').doc(window.configClientKey).collection('sales');
  const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
  const saleId = uuidv4();
  const historyEntry = {
    date : new Date (),
    amountGiven: saleData.amountGiven,
    description: saleData.due > 0? 'Compra con deuda': 'Compra con pago total',
    due:saleData.due,
  }
  let updatedUserData = null;

  if (saleData.userId && saleData.userId.length > 0) {
    const userDoc = await usersRef.doc(saleData.userId).get();
    
    if (userDoc.exists) {
      const userData = userDoc.data();
      const currentDue = userData.due || 0;
      const currentSales = userData.sales || [];
      const currentPoints = userData.points || 0;
      const newPoints = saleData.asignPoints ? parseFloat(currentPoints) + parseFloat(saleData.points) : currentPoints;
      updatedUserData = {
        due: currentDue + saleData.due,
        sales: [...currentSales, saleId],
        points: newPoints,
      };
      batch.set(usersRef.doc(saleData.userId), removeUndefinedFields(updatedUserData), { merge: true });
    }
  }
  const history = [historyEntry];
  batch.set(salesRef.doc(saleId), removeUndefinedFields({
    ...saleData,
    history,
    author: currentUser,
  }), { merge: true });

  const productDocs = await Promise.all(saleData.products.map(product => productsRef.doc(product.id).get()));
  
  productDocs.forEach((productDoc, index) => {
    if (!productDoc.exists) {
      console.error(`Producto con ID ${saleData.products[index].id} no encontrado.`);
      return;
    }

    const productData = productDoc.data();
    const product = saleData.products[index];
    
    const newStorage = (parseFloat(productData.storage) || 0) - parseFloat(product.quantity);

    const newHistoryEntry = {
      date: new Date(),
      transactionType: 'sale',
      note: saleData.generalNote,
      branch: saleData.branch,
      sale: saleId,
      quantity: -product.quantity,  
      storage: newStorage,
      variant: product.variant || null,
    };

    const newHistory = [...(productData.history || []), newHistoryEntry];

    const newBranches = {
      ...productData.branches,
      [saleData.branch]: {
        ...(productData.branches?.[saleData.branch] || {}),
        general: (parseFloat(productData.branches?.[saleData.branch]?.general) || 0) - parseFloat(product.quantity),
        ...(product.variant ? {
          [product.variant]: (parseFloat(productData.branches?.[saleData.branch]?.[product.variant]) || 0) - parseFloat(product.quantity)
        } : {})
      }
    };

    let updates = {
      history: newHistory,
      storage: newStorage,
      branches: newBranches,
    };

    if (product.variant) {
      const variantIndex = productData.variables ? productData.variables.findIndex(v => v.name === product.variant) : -1;
      let newVariables = productData.variables ? [...productData.variables] : [];

      if (variantIndex > -1) {
        newVariables[variantIndex] = {
          ...newVariables[variantIndex],
          storage: (parseFloat(newVariables[variantIndex].storage) || 0) - parseFloat(product.quantity),
        };
      } 
      updates.variables = newVariables;
    }

    batch.set(productsRef.doc(product.id), removeUndefinedFields(updates), { merge: true });
  });

  await batch.commit();

  return {
    id: saleId,
    data: saleData,
    updatedUserData,
  };
}

export const requestGetSales = async (props:Object):Promise<any> => {
  let start = props.startDate ? new Date(props.startDate) : new Date('2017-01-01');
  let end = props.endDate ? new Date(props.endDate) : new Date();

  try {
    let salesRef = firestore.collection('clients').doc(window.configClientKey).collection('sales');
    salesRef = salesRef
      .where('date', '>=', start)
      .where('date', '<=', end);
    const salesQuery = salesRef
      .orderBy('date', 'desc')
      .limit(30); // Ultimas 30 ventas
    const salesSnapshot = await salesQuery.get();
    const sales = [];
    salesSnapshot.forEach(doc => {
      sales.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    return sales;
  } catch (error) {
    console.error('Error obteniendo ventas:', error);
    throw error;
  }
};


export const requestGetSalesWithIds = async (salesIds) => {
  try {
    const salesRef = firestore.collection('clients').doc(window.configClientKey).collection('sales');
    const sales = [];

    // Firestore tiene un límite de 10 elementos para la consulta 'in'
    const chunkSize = 10;

    // Dividir salesIds en fragmentos de tamaño chunkSize
    for (let i = 0; i < salesIds.length; i += chunkSize) {
      const chunk = salesIds.slice(i, i + chunkSize);

      const salesSnapshot = await salesRef.where('__name__', 'in', chunk).get();

      salesSnapshot.forEach(doc => {
        sales.push({
          id: doc.id,
          data: doc.data()
        });
      });
    }

    return sales;
  } catch (error) {
    console.error('Error obteniendo ventas:', error);
    throw error;
  }
};


export async function requestUpdateIncomeExpense(inventoryData) {
  try {
    const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
    const idUser = window.userRes.uid;
    return firestore.runTransaction(async (transaction) => {
      const productDocs = await Promise.all(inventoryData.products.map(product => transaction.get(productsRef.doc(product.id))));

      productDocs.forEach((productDoc, index) => {
        if (!productDoc.exists) {
          console.error(`Producto con ID ${inventoryData.products[index].id} no encontrado.`);
          return; 
        }

        const productData = productDoc.data();
        const product = inventoryData.products[index];

       
        const newStorage = (parseFloat(productData.storage) || 0) + parseFloat(product.quantity);

        const newHistoryEntry = {
          date: new Date(),
          author:idUser,
          transactionType: product.transactionType,
          note: product.note,
          branch: inventoryData.branch,
          quantity:product.quantity,
          storage: newStorage,
          variant: product.variant || null,
        };

        const newHistory = [...(productData.history || []), newHistoryEntry];

        const newBranches = {
          ...productData.branches,
          [inventoryData.branch]: {
            ...(productData.branches?.[inventoryData.branch] || {}),
            general: (parseFloat(productData.branches?.[inventoryData.branch]?.general) || 0) + parseFloat(product.quantity),
            ...(product.variant ? {
              [product.variant]: (parseFloat(productData.branches?.[inventoryData.branch]?.[product.variant]) || 0) + parseFloat(product.quantity)
            } : {})
          }
        };

        let updates = {
          history: newHistory,
          storage: newStorage,
          branches: newBranches,
        };

        if (product.variant) {
          const variantIndex = productData.variables ? productData.variables.findIndex(v => v.name === product.variant) : -1;
          let newVariables = productData.variables ? [...productData.variables] : [];

          if (variantIndex > -1) {
            newVariables[variantIndex] = {
              ...newVariables[variantIndex],
              storage: (parseFloat(newVariables[variantIndex].storage) || 0) + parseFloat(product.quantity),
            };
          } 
          updates.variables = newVariables;
        }

        transaction.set(productsRef.doc(product.id), updates, { merge: true });
      });

      return "Transacción completada exitosamente.";
    });
  } catch (error) {
    console.error('Transaction failed:', error);
    throw error;
  }
}



export async function  requestUpdateTransfer(inventoryData) {
  try {
    const productsRef = firestore.collection('clients').doc(window.configClientKey).collection('products');
    const idUser = window.userRes.uid;
    return firestore.runTransaction(async (transaction) => {
      const productDocs = await Promise.all(inventoryData.products.map(product => transaction.get(productsRef.doc(product.id))));

      productDocs.forEach((productDoc, index) => {
        if (!productDoc.exists) {
          console.error(`Producto con ID ${inventoryData.products[index].id} no encontrado.`);
          return; 
        }

        const productData = productDoc.data();
        const product = inventoryData.products[index];
        const nota = inventoryData.note + '(' +inventoryData.branchOriginName +'-'+ inventoryData.branchDestinationName + ')';
        const newHistoryEntry = {
          date: new Date(),
          author:idUser,
          transactionType: product.transactionType,
          note: nota,
          storage: product.quantity,
          variant: product.variant || null,
        };

        const newHistory = [...(productData.history || []), newHistoryEntry];

        const newBranches = {
          ...productData.branches,
          [inventoryData.branchOrigin]: {
            ...(productData.branches?.[inventoryData.branchOrigin] || {}),
            general: (parseFloat(productData.branches?.[inventoryData.branchOrigin]?.general) || 0) - parseFloat(product.quantity),
            ...(product.variant ? {
              [product.variant]: (parseFloat(productData.branches?.[inventoryData.branchOrigin]?.[product.variant]) || 0) - parseFloat(product.quantity)
            } : {})
          },
          [inventoryData.branchDestination]: {
            ...(productData.branches?.[inventoryData.branchDestination] || {}),
            general: (parseFloat(productData.branches?.[inventoryData.branchDestination]?.general) || 0) + parseFloat(product.quantity),
            ...(product.variant ? {
              [product.variant]: (parseFloat(productData.branches?.[inventoryData.branchDestination]?.[product.variant]) || 0) + parseFloat(product.quantity)
            } : {})
          }
        };

        let updates = {
          history: newHistory,
          branches: newBranches,
        };
        transaction.set(productsRef.doc(product.id), updates, { merge: true });
      });

      return "Transacción completada exitosamente.";
    });
  } catch (error) {
    console.error('Transaction failed:', error);
    throw error;
  }
}


export async function requestPayDue(payDueData) {
  const batch = firestore.batch();
  const usersRef = firestore.collection('clients').doc(window.configClientKey).collection('users');
  const salesRef = firestore.collection('clients').doc(window.configClientKey).collection('sales');

  const historyEntry = {
    date: new Date(),
    amountGiven: payDueData.amountGiven,
    description: payDueData.due > 0 ? 'Pago parcial de deuda' : 'Pago total de deuda',
    due: payDueData.due,
  };

  const userDoc = await usersRef.doc(payDueData.userId).get();
  if (userDoc.exists) {
    const updatedUserData = {
      due: payDueData.due,
    };
    batch.set(usersRef.doc(payDueData.userId), removeUndefinedFields(updatedUserData), { merge: true });
  }
  let  updatedHistory;
  const saleDoc = await salesRef.doc(payDueData.saleId).get();
  if (saleDoc.exists) {
    const saleData = saleDoc.data();
    let updatedHistory = saleData.history ? [...saleData.history, historyEntry] : [historyEntry];

    batch.set(salesRef.doc(payDueData.saleId), { due: payDueData.due, history: updatedHistory }, { merge: true });
  } 

  await batch.commit();

  return {
    id: payDueData.saleId,
    data: { due: payDueData.due, history: updatedHistory },
  };
}


export const requestGetCashRegister = async (): Promise<any> => {
  try {
    const cashRegisterRef = firestore.collection('clients').doc(window.configClientKey);
    const doc = await cashRegisterRef.get();
    const data = doc.data();
    const cashRegister = data.cashRegister || {}; 
    return cashRegister;
  } catch (error) {
    console.error('Error obteniendo caja registradora:', error);
    throw error;
  }
};



export const requestUpdateCashRegister = async (cashRegisterData): Promise<any> => {
  try {
    const cashRegisterRef = firestore.collection('clients').doc(window.configClientKey);
    await cashRegisterRef.set({ cashRegister: cashRegisterData }, { merge: true });
    return cashRegisterData;
  } catch (error) {
    console.error('Error obteniendo caja registradora:', error);
    throw error;
  }
};