/* @flow */

import deepMerge from 'lodash/merge';

import {
    requestSetProductToOffers,
    requestGetProductsOnOffer,
    requestRemoveProductsToOffers,
    requestRemoveProductToOffers,
} from './client';

import c from '../constants';


export function addProductToOffers(productId, offerInfo) {
  return (dispatch) => {
    return requestSetProductToOffers(productId, offerInfo)
      .then((res) => {
        return res; 
      })
      .catch((error) => {
        throw error; 
      });
  };
}



export function getOffers(){
  return async (dispatch) => {
    try {
      const productsData = await requestGetProductsOnOffer();
      dispatch({
        type: c.UPDATE_PRODUCTS,
        payload:productsData,
      });
      return productsData; 
      
    } catch (error) {
      console.error('Error getting products:', error);
      throw error;
    }
  };
}


export function removeOffers(productId) {
  return async (dispatch, getState) => {
    const state = getState();
    const products = state.products.list;
    const productIndex = products.findIndex(product => product.id === productId);
    if (productIndex === -1) {
      throw new Error('Producto no encontrado');
    }
    const updatedProduct = { ...products[productIndex] };
    const oldOffers = { ...updatedProduct.data.offers };
    updatedProduct.data.offers = {};
    const updatedProducts = [
      ...products.slice(0, productIndex),
      updatedProduct,
      ...products.slice(productIndex + 1)
    ];

    dispatch({
      type: c.UPDATE_PRODUCTS,
      payload: updatedProducts,
    });
    try {
      await requestRemoveProductsToOffers(productId);
    } catch (error) {
      console.error('Error eliminando todas las ofertas:', error);
      dispatch({
        type: c.UPDATE_PRODUCTS,
        payload: products,
      });

      window.alert('Error eliminando todas las ofertas');
    }
  };
}


export function removeOffer(productId, offerId) {
  return async (dispatch, getState) => {
    const state = getState();
    const products = state.products.list;
    const productIndex = products.findIndex(product => product.id === productId);
    if (productIndex === -1) {
      throw new Error('Producto no encontrado');
    }
    const updatedProduct = { ...products[productIndex] };
    const oldOffers = { ...updatedProduct.data.offers };
  
    delete updatedProduct.data.offers[offerId];
    const updatedProducts = [
      ...products.slice(0, productIndex),
      updatedProduct,
      ...products.slice(productIndex + 1)
    ];

    dispatch({
      type: c.UPDATE_PRODUCTS,
      payload: updatedProducts,
    });

    try {
      await requestRemoveProductToOffers(productId, updatedProduct.data.offers);
    } catch (error) {
      console.error('Error eliminando la oferta:', error);
      
      dispatch({
        type: c.UPDATE_PRODUCTS,
        payload: products,
      });

      window.alert('Error eliminando la oferta');
    }
  };
}
