/* @flow */

import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import UserIcon from '@rsuite/icons/legacy/User';
import ShoppingCartIcon from '@rsuite/icons/legacy/ShoppingCart';
import Badge from 'rsuite/Badge';
import Avatar from 'rsuite/Avatar';
import Navbar from 'rsuite/Navbar';
import Nav from 'rsuite/Nav';

import {
  useNavigate,
  useLocation,
  NavLink,
} from 'react-router-dom';

import './Header.css';

function Header(props: Object):Object {
  const account = useSelector(state => state.account);
  const user = account.user;
  const cart = account.cart;

  return (
    <div
      className={classNames('mb-header', {})}
    >
      <Navbar>
        <Navbar.Brand>
        </Navbar.Brand>
        <Nav>
          <NavLink strict="true" to="/">
            {window.lc('Home')}
          </NavLink>
          {user ? (
            <NavLink strict="true" to="/search">
              {window.lc('Search')}
            </NavLink>
          ) : null}
          {user && !user.admin ? (
            <NavLink strict="true" to="/favorites">
              {window.lc('Favorites')}
            </NavLink>
          ) : null}
          {user && !user.admin ? (
            <NavLink strict="true" to="/my-orders">
              {window.lc('My Orders')}
            </NavLink>
          ) : null}
          {user && user.admin && false ? (
            <NavLink strict="true" to="/create-branch">
              {window.lc('Create Branch')}
            </NavLink>
          ) : null}
          {user && user.admin && false ? (
            <NavLink strict="true" to="/create-product">
              {window.lc('Create Product')}
            </NavLink>
          ) : null}
          {user && user.admin && false ? (
            <NavLink strict="true" to="/statistics">
              {window.lc('Statistics')}
            </NavLink>
          ) : null}
          {user && user.admin ? (
            <NavLink strict="true" to="/orders">
              {window.lc('Orders')}
            </NavLink>
          ) : null}
          {user && user.admin ? (
            <NavLink strict="true" to="/admin">
              {window.lc('Admin')}
            </NavLink>
          ) : null}
        </Nav>

        <Nav pullRight>
          {user ? (
            <NavLink strict="true" to="/cart">
              {cart && Object.keys(cart).length ? (
                <Badge content={Object.keys(cart).length}>
                  {window.lc('Cart')}<ShoppingCartIcon />
                </Badge>
              ) : (
                <>
                  {window.lc('Cart')}<ShoppingCartIcon />
                </>
              )}
            </NavLink>
          ) : null}
          {user ? (
            <NavLink
              strict="true"
              to="/account"
              className="mb-account-link"
            >
              <span style={{ display: 'block' }}>
                {window.lc('Account')}
              </span>
              {user.avatarUrl ? (
                <Avatar
                  src={user.avatarUrl}
                />
              ) : (
                <Avatar>
                  <UserIcon />
                </Avatar>
              )}
            </NavLink>
          ) : (
            <NavLink strict="true" to="/enter">
              {window.lc('Enter')}
            </NavLink>
          )}
        </Nav>
      </Navbar>
    </div>
  );
}

export default Header;
